<template>
  <section>
    <!-- <div class="searchBar line"></div> -->
    <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title">
            <i class="fas fa-cog"></i>
            <select>
              <option>{{ $t('table.head.id') }}</option>
              <option>닉네임</option>
              <option>상위유저</option>
              <option>{{ $t('common.memLevel') }}</option>
            </select> :
          </div>
          <input type="text" class="mr-5" v-model="reqData.memId" />
        </div>
        <button class="btn-search" type="button" @click="pageSeach">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="searchBar">
        <button class="bbtn">전체선택</button>
        <button class="rbtn">일괄지급</button>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <colgroup>
            <col width="5%">
            <col width="7%">
            <col width="7%">
            <col width="13.5%">
            <col width="13.5%">
            <col width="13.5%">
            <col width="13.5%">
            <col width="13.5%">
            <col width="13.5%">
          </colgroup>
          <table-head :headInfo="headInfo" />
          <tbody>
            <tr>
              <td>
                <input type="checkbox">
              </td>
              <td>ID</td>
              <td>Nickname</td>
              <td>123,456</td>
              <td>123,456,789</td>
              <td>500,000</td>
              <td>1,000,000</td>
              <td>1,000,000</td>
              <td>2023-04-04</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="betList.length !== 0" @goPage="goPage" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { betList, getCode } from '@/api/member.js'
import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'

export default {
  name: 'BetList',
  components: {
    SearchFilter,
    DateSelector,
    TableHead,
    UiCheck,
    subTitle,
    Pagination
  },
  data: function () {
    return {
      tableData: {},
      headInfo: {
        fstColumn: false,
        dataList: ['chk', 'memId', 'memNick','cashholing', 'pointAmt','lastIn', 'lastOut', 'lastChange', 'memRegDt']
      },
      reqData: {
        page: 1,
        count_per_list: '30',
        betType: 'kgon',
        gameType: '1',
        memId: '',
        oldYn: 'N'
      },
      pageInfo: {},
      betList: [],
      codeList: [],
      convertData: {},
      gameType: {},
      tabList: {},
      currentPage: '',
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      currentPageName: ''
    }
  },
  methods: {
    async setTabList () {
      const group = this.$route.meta.group
      // console.log(group);
      const gameList = await this.$store.getters.gameList
      // console.log(gameList);
      if (gameList.length) {
        const gameObj = gameList.find(obj => {
          return obj.code === group
        })
        this.tabList = gameObj.child
      }
    },
    async setGameType () {
      const pathStr = this.$route.path.split('/')
      this.reqData.vendorCode = pathStr[4]
      this.currentPage = pathStr[4]
    },
    pageSeach () {
      this.lastDataRefector(this.currentPage, 1)
    },
    setTitleName () {
      this.tabList.forEach(item => {
        if (this.currentPage.toString() === item.code) {
          this.currentPageName = item.codeName
        } else {
          if (this.currentPage === 'all') {
            this.currentPageName = '전체보기'
          }
        }
      })

      this.emitter.emit('gameTitle', this.currentPageName)
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    goPage (pageNum) {
      const code = this.reqData.vendorCode
      this.lastDataRefector(code, pageNum)
    },
    async lastDataRefector (code, pageNum) {
      this.emitter.emit('Loading', true)
      window.scrollTo(0, 0)
      if (!pageNum) {
        pageNum = 1
      }
      const listRes = await this.getBetList(code, pageNum)
      const pageInfo = listRes.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
      }
      const list = listRes.data.list
      this.emitter.emit('Loading', false)
    }
  },
  watch: {
    gameList: {
      handler (value) {
        this.setTabList()
      }
    },
    tabList: {
      handler (value) {
        if (this.tabList.length > 0 && this.currentPage) {
          this.setTitleName()
        }
      }
    },
    currentPage () {
      this.lastDataRefector(this.currentPage, 1)
    }
  },
  computed: {
    gameList () {
      return this.$store.getters.gameList
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    this.setStartDate()
    this.setEndDate()
    await this.setTabList()
    await this.setGameType()
    await this.lastDataRefector(this.reqData.vendorCode, 1)
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.searchwrap{justify-content: space-between;}
.searchwrap > div{display: flex;}
.bbtn, .rbtn { padding: 6px 10px; color: #fff; margin-left: 15px; cursor: pointer; font-size: 14px;}
.bbtn {background: rgb(55, 55, 139)}
.rbtn {background: #e1534e}
.searchbar-content{margin: 0 5px;}
</style>
